<ng-container *transloco="let t; read: 'actionable'">
  <div class="modal-container">
    <div class="modal-header">
      <h4 class="modal-title">{{t('title')}}</h4>
      <button type="button" class="btn-close" [attr.aria-label]="t('close')" (click)="modal.close()"></button>
    </div>
    <div class="modal-body scrollable-modal">
      @if (currentLevel.length > 0) {
        <button class="btn btn-secondary w-100 mb-3 text-start" (click)="handleBack()">
          ← {{t('back-to', {action: t(currentLevel[currentLevel.length - 1])})}}
        </button>
      }

      <div class="d-grid gap-2">
        @for (action of currentItems; track action.title) {
          @if (willRenderAction(action)) {
            <button class="btn btn-outline-primary text-start d-flex justify-content-between align-items-center w-100"
                    (click)="handleItemClick(action)">
              {{t(action.title)}}
              @if (action.children.length > 0 || action.dynamicList) {
                <span class="ms-1">→</span>
              }
            </button>
          }
        }
      </div>
    </div>
  </div>
</ng-container>
